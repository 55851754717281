<template>
  <div id="about">
    <header>
      <div class="headerTop">
        <div class="container">
          <div class="logo">
            <h1>
              <img src="@/assets/images/logo.png" alt="logo" />
            </h1>
          </div>
          <ul>
            <li><router-link to="/">首页</router-link></li>
            <li><router-link to="wisdom">智量百业</router-link></li>
            <li><router-link to="shop">同城好店</router-link></li>
            <li class="cur">
              <router-link to="assistant">抖微助手</router-link>
            </li>
            <li><router-link to="flyfishoutlets">飞鱼系统</router-link></li>
            <li><router-link to="commerce">跨境电商</router-link></li>
            <!-- <li><router-link to="contact">联系我们</router-link></li> -->
            <li><router-link to="about">关于我们</router-link></li>
          </ul>
        </div>
      </div>
      <img src="@/assets/images/assistant_banner1.jpg" alt="" />
    </header>
    <section class="pd100 assistant_1">
      <div class="title">
        <h4>
          <img src="@/assets/images/assistant-2.png" alt="" />
        </h4>
        <p>抖音关键词精准引流</p>
      </div>
      <div class="main">
        <div class="assistant-bg">
          <img src="@/assets/images/assistant-bg-1.jpg" alt="" />
        </div>
        <div class="item item_1">
          <img src="@/assets/images/assistant-icon-1.jpg" alt="" />
          <p>智能养号</p>
        </div>
        <div class="item item_2">
          <img src="@/assets/images/assistant-icon-2.jpg" alt="" />
          <p>精准引流</p>
        </div>
        <div class="item item_3">
          <img src="@/assets/images/assistant-icon-3.jpg" alt="" />
          <p>线索分析</p>
        </div>
        <div class="item item_4">
          <img src="@/assets/images/assistant-icon-4.jpg" alt="" />
          <p>智能截流</p>
        </div>
        <div class="item item_5">
          <img src="@/assets/images/assistant-icon-5.jpg" alt="" />
          <p>客服聊天</p>
        </div>
        <div class="item item_6">
          <img src="@/assets/images/assistant-icon-6.jpg" alt="" />
          <p>添加微信</p>
        </div>
      </div>
    </section>
    <!-- 抖微助手 -->
    <section class="pd100 assistant">
      <div class="container">
        <div class="item-title">
          <img src="@/assets/images/title-icon.jpg" alt="" />
          <span>抖音关键词精准引流</span>
        </div>
        <div class="main">
          <ul>
            <li>
              <p>独创高效引流系统</p>
              <p>独创裂变引流系统，1变5、5变25指数级裂变</p>
            </li>
            <li>
              <p>抖音智能养号涨粉</p>
              <p>分享引流自动锁客，各级数据清晰明了</p>
            </li>
            <li>
              <p>抖音精准客户变现</p>
              <p>搭建私域流量池，变现涨粉相互协同</p>
            </li>
          </ul>
          <img src="@/assets/images/assistant-1.jpg" alt="" />
        </div>
      </div>
    </section>
    <section class="container pd100 shop_2">
      <div class="item-title">
        <img src="@/assets/images/title-icon.jpg" alt="" />
        <span>整体解决方案详情</span>
      </div>
      <div class="main">
        <div class="item">
          <img src="@/assets/images/assistant-3.jpg" alt="" />
          <ul>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>根据客户需求，设定IP人设，智能养号</span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span
                >根据甲方行业特点和用户需求，24小时持续 <br />收集潜在意向客户
              </span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span
                >根据行业特点，对潜在客户进行推广，引导 <br />用户反馈和关注
              </span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>根据推广情况，对精准用户进行在线转化变现</span>
            </li>
          </ul>
        </div>
        <div class="item">
          <ul>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span
                >根据行业和用户画像，结合大数据系统学习
                <br />优化搜索关键词，精准收集潜在意向客户。
              </span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>定时定向精准同行粉丝转化，事半功倍。</span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span
                >提供行业实战话术模板，以及即时私信聊天
                <br />功能，实现精准线索的高效转化。
              </span>
            </li>
          </ul>
          <img src="@/assets/images/assistant-4.jpg" alt="" />
        </div>
      </div>
    </section>
    <!-- 业务合作 -->
    <section class="pd60 contact">
      <div class="container">
        <div class="item-title">
          <img src="@/assets/images/title-icon.jpg" alt="" />
          <span>业务合作</span>
        </div>
      </div>
      <div class="main">
        <div class="container">
          <img src="@/assets/images/contact-2.png" alt="" />
          <p>
            请将合作资料发送至邮箱：1487986688@qq.com
            或者加微信：13410419943
          </p>
        </div>
      </div>
    </section>
    <footer>
      <div class="main container pd60">
        <div class="item">
          <h4>关于我们</h4>
          <p>公司介绍</p>
          <p>业务合作</p>
        </div>
        <div class="item">
          <h4>联系我们</h4>
          <p>邮箱：1487986688@qq.com</p>
          <p>电话（微信同名）：13410419943，周一至周日 9:30 - 22:30</p>
          <!-- <p>地址：深圳市福田区车公庙泰然八路安华工业区6栋613</p> -->
        </div>
        <div class="wx">
          <div class="wx-item">
            <img src="@/assets/images/wx-1.jpg" alt="" />
            <p>扫码关注公众号</p>
          </div>
          <div class="wx-item">
            <img src="@/assets/images/wx-2.jpg" alt="" />
            <p>业务合作</p>
          </div>
        </div>
      </div>
      <div class="copyright container pd60">
        <p>©2019-2024 深圳漠阳风科技有限公司 版权所有</p>
        <a href="http://beian.miit.gov.cn/">粤ICP备19157531号-3</a>
      </div>
    </footer>
  </div>
</template>
<script>
import '@/assets/css/base.css'
import '@/assets/css/style.css'
export default {}
</script>
<style lang="less" scoped>
.assistant_1 .main {
  position: relative;
  margin-top: 100px;
  height: 700px;
  .assistant-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 545px;
    height: 500px;
    background: url(../assets/images/assistant-bg.jpg) no-repeat;
    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 195px;
      height: 392px;
    }
  }
  .item{
      position: absolute;
      text-align: center;
      p{
          font-size: 25px;
          color: #dd1136;
          margin-top: 10px;
      }
  }
  .item_1{
      top: 0;
      left: 560px;
  }
   .item_2{
      top: 0;
      right: 560px;
  }
  .item_3{
      top: 290px;
      left: 330px;
  }
   .item_4{
      top: 290px;
      right: 330px;
  }
  .item_5{
      bottom: 0;
      left: 560px;
  }
   .item_6{
      bottom: 0;
      right: 560px;
  }
}
</style>
